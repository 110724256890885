import { Stack } from "@mui/material";

const Contact = () => {
 
  return (
    <Stack sx={{ alignItems: "center" }}>
      <h1>Nothing here yet</h1>
    </Stack>
  );
};
export default Contact;
